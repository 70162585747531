// THIS FILE IS GENERATED BY 'npm run set-env-variables'

export const environment = {
  production: true,

  // Configuration/Feature-switches:
  envName: 'staging',
  useServiceWorker: true,

  // Configuration of content
  appName: 'Helpful Information',
  mainPageHeader: 'Dorcas provides Helpful Information.',
  mainPageIntroduction: 'Виберіть будь ласка ваш район:',

  // Regions:
  regions: 'en,ukr,uzhhorod,mukachevo',
  regionsLabels: 'English,Українська,Ужгородський район,Мукачевський район',

  // Third-party tokens:
  // Google Sheets API:
  google_sheets_api_key: 'AIzaSyABTmWVe1tNshRPDxkfHqnbdC57W_EEB6g',
  google_sheets_api_url: 'https://sheets.googleapis.com/v4/spreadsheets',
  google_sheets_sheet_ids: '17xRie9JiKPVYvObiDUtpEZFti-qcI98vEFrbXx_Jde8,1MWwYofNOhNFhEMtXnaPQIr21AuYcZQsuq7cUdRx6gNo,1lo50cZ-u6Wy_ZcXesQJr064JsLNBvVt0dOJHLyNphVk,1OHxVrBnX5MaA8Pi0h-64uQriQzx84eu_P4EJja5YQKI',

  // Application Insights
  appInsightsConnectionString: '',
};
